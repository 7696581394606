import React from "react"
import { graphql } from "gatsby"
import BlocksRenderer from "../components/blocks-renderer"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"

//import ShareButtons from "../components/share-buttons"
//import Author from "../components/author"

const ArticlePage = ({ data }) => {
  //console.log("Data:", data)
  //console.log("Data - Blocks:", data.strapiArticle.blocks)

  const { strapiArticle: article } = data
  const { seo } = article
  const structuredData = seo.structuredData
  //console.log("Data - structuredData:", structuredData)

  return (
    <div as="article">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "article",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={article.seo.metaTitle}
        description={article.seo.metaDescription}
        image={article.seo.metaImage.localFile.publicURL}
        post={true}
        metaRobots={article.seo.metaRobots}
        metaSocial={article.seo.metaSocial}
      />

      <BlocksRenderer blocks={article.blocks || []} />

      {/* <ShareButtons
        url={url}
        seo={article.seo}
        quote={quote}
        hashtag={hashtag}
      /> */}

      {/*  <Author author={author} /> */}
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      blocks {
        ...Blocks
      }
      author {
        name
        email
        position
        avatar {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                height: 60
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        articles {
          # title
          #headings {
          #  title
          #}
          slug
        }
      }
      seo {
        ...StrapiSeo
        structuredData {
          author {
            _type
            name
            email
            position
            #avatar {
            #  alternativeText
            #  caption
            #  localFile {
            #    url
            #  }
            #}
          }
          updatedAt
          datePublished
        }
        metaRobots
      }
    }
  }
`

export default ArticlePage
